import type { StatusEnum } from '@/pages/dashboard/Receipts/enums';
import { operationsAPI } from './apis';
import type { Filters, Receipt } from './models/ReceiptsModels';

export default class OperationService {
  static downloadReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`v1/receipts/${id}/download-original`, {
      signal,
      responseType: 'blob',
    });
  }

  static async getReceiptsWithPagination(
    fiscalYear: number | null | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined,
    statusFilter: StatusEnum | null,
    filters: Filters | null,
    signal?: AbortSignal
  ) {
    let url = '/v1/receipts/admin/all';
    const queryParameters: string[] = [];
    if (fiscalYear) {
      queryParameters.push(`fiscalYear=${fiscalYear}`);
    }
    if (pageNumber) {
      queryParameters.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      queryParameters.push(`pageSize=${pageSize}`);
    }
    if (statusFilter) {
      queryParameters.push(`status=${statusFilter}`);
    }
    if (filters) {
      if (filters.searchQuery) {
        queryParameters.push(`searchQuery=${filters.searchQuery}`);
      }
    }
    if (queryParameters.length > 0) {
      url += '?' + queryParameters.join('&');
    }
    return operationsAPI.get(url, {
      signal,
    });
  }

  static getReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`v1/receipts/${id}/admin`, {
      signal,
    });
  }


  static async updateReceipt(
    id: string | undefined,
    data: Receipt,
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(
      `/v1/receipts/${id}/admin`,
      data,
      {
        signal,
      }
    );
  }


}


