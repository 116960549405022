import type { FC } from 'react';
import { useEffect, useState } from 'react';

import {
  chevronDown,
  companyLogo,
  plus,
  search,
  tuntel,
  wecraft,
} from '@/assets/images/dashboard';
import { useAppComponentVisible } from '@/hooks';

import styles from '../Dashboard.module.scss';

interface CompanyProps {}

const companies = [
  { logo: wecraft, name: 'WECRAFT' },
  { logo: tuntel, name: 'TUNTEL' },
];

const Company: FC<CompanyProps> = () => {
  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  useEffect(() => {
    if (isMenuOpen) {
      setFilteredCompanies(companies);
      setSearchValue('');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const filteredCompanies = companies.filter(({ name }) =>
      name.toLowerCase().includes(lowerCaseSearchValue)
    );

    setFilteredCompanies(filteredCompanies);
  }, [searchValue]);

  return (
    <div className={styles.company}>
      <div
        className={styles.images}
        onClick={handleToggleMenu}
        onBlur={() => setIsMenuOpen(false)}
      >
        <img src={companyLogo} alt="Company Logo" />
        <img src={chevronDown} alt="More" />
      </div>

      {isMenuOpen && (
        <div ref={menuRef} className={styles.menu}>
          <div className={styles.inputContainer}>
            <img src={search} alt="Search" />
            <input
              placeholder="Company name"
              onChange={handleSearch}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>

          {filteredCompanies.map(({ logo, name }, idx) => (
            <div className={styles.companyItem} key={idx}>
              <img src={logo} alt={name} height={30} width={30} />
              <p>{name}</p>
            </div>
          ))}

          {searchValue && filteredCompanies.length === 0 && (
            <button>
              <img src={plus} alt="Add" />
              Ajouter cette entreprise
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Company;
