import type { CurrencyType, Plan } from '@/services/models';

import { settingsAPI } from './apis';

export default class SettingsService {
  static getBanksList(signal?: AbortSignal) {
    return settingsAPI.get(`v1/banks`, {
      signal,
    });
  }

  static deleteBank(id: string, signal?: AbortSignal) {
    return settingsAPI.delete(`v1/banks/${id}`, {
      signal,
    });
  }

  static updateBank(id: string, data: FormData, signal?: AbortSignal) {
    return settingsAPI.put(`v1/banks/${id}`, data, {
      signal,
    });
  }

  static createBank(data: FormData, signal?: AbortSignal) {
    return settingsAPI.post(`v1/banks/`, data, {
      signal,
    });
  }

  static async getCurrencies(signal?: AbortSignal) {
    return settingsAPI.get<CurrencyType[]>('v1/currencies', {
      signal,
    });
  }

  static getPlans(signal?: AbortSignal) {
    return settingsAPI.get(`v1/plans/`, {
      signal,
    });
  }

  static createPlan(data: Plan, signal?: AbortSignal) {
    return settingsAPI.post(`v1/plans`, data, {
      signal,
    });
  }

  static updatePlan(id: string, plan: Plan, signal?: AbortSignal) {
    return settingsAPI.put(`v1/plans/${id}`, plan, {
      signal,
    });
  }

  static deletePlan(id: string, signal?: AbortSignal) {
    return settingsAPI.delete(`v1/plans/${id}`, {
      signal,
    });
  }
}
