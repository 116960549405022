import { v4 as uuidv4 } from 'uuid';

import i18n from '@/i18n';
import store from '@/store';
import { setConnectedUser } from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';

import { appToast } from '../ToastContainers';

const defaultAPIRequestMiddleware = (config: any) => {
  const accessToken = localStorage.getItem('access-token');
  const browserFingerprint = localStorage.getItem('browser-fingerprint');

  if (config.headers) {
    if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
    config.headers['X-B3-Traceid'] = uuidv4();
    config.headers['X-App-Version'] = APP_VERSION;
    config.headers['X-Caller-Name'] = 'CONSOLE';
    config.headers['X-Device-FP'] = browserFingerprint;
  }

  return config;
};

const errorMsgs: Record<number, string> = {
  500: 'Server error. Please try again later.',
  503: 'Service Unavailable. Please try again later.',
  401: 'Unauthorized',
  403: "You don't have permissions to access this resource.",
};
const errorCodes = Object.keys(errorMsgs);

const defaultAPIResponseMiddleware = async (err: any, axiosAPI: any) => {
  // HANDLE GLOBAL 500, 503, 401 AND 403 STATUS CODE ERRORS
  const { status } = err.response || {};

  if (errorCodes.includes(String(status)))
    appToast.error(i18n.t(errorMsgs[status]));

  // HANDLE REFRESH TOKENS
  const originalConfig = err.config;

  if (
    !originalConfig.url.endsWith('/auth/refresh') &&
    err.response &&
    err.response.status === 401 &&
    (err.response.data.message === 'Revoked, expired or invalid token' ||
      'Unauthorized access')
  ) {
    try {
      // TODO: SAVE TOKENS
      // TODO: ADD Authorization HEADER TO config AND RETRY REQUEST
      localStorage.removeItem('access-token');
      store.dispatch(setConnectedUser(null));
      window.location.reload();

      return axiosAPI(originalConfig);
    } catch (err2) {
      return Promise.reject(err2);
    }
  }

  return Promise.reject(err);
};

export { defaultAPIRequestMiddleware, defaultAPIResponseMiddleware };
