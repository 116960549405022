import type { FC } from 'react';

import { dark, light } from '@/assets/images';
import { useAppTheme } from '@/hooks';

interface ThemeButtonProps {}

const ThemeButton: FC<ThemeButtonProps> = () => {
  const { theme, toggleTheme } = useAppTheme();

  return (
    <>
      {theme === 'dark' ? (
        <button
          onClick={toggleTheme}
          className="flex cursor-pointer p-[4px] bg-sec rounded-full min-w-fit	"
        >
          <div className="bg-first gap-2 items-center h-full flex p-1 rounded-full">
            <img alt="light" src={light} />
            <h4 className="pr-2 font-bold color-black">Light</h4>
          </div>
        </button>
      ) : (
        <button
          onClick={toggleTheme}
          className="flex cursor-pointer p-[4px] bg-sec rounded-full min-w-fit"
        >
          <div className="bg-first gap-2 items-center flex p-2 rounded-full">
            <h4 className="pl-2 font-bold color-black">Dark</h4>
            <img className="w-[25px]" alt="dark" src={dark} />
          </div>
        </button>
      )}
    </>
  );
};

export default ThemeButton;
