import { AuthGuard, RolesGuard } from '@/lib/guards';
import { Navigate, Route } from 'react-router-dom';

import { DashboardLayout } from '@/components/layouts';
import { Role } from '../lib/guards/RolesGuard';
import type { RouteType } from '.';
import { lazy } from 'react';
import ReceiptPage from '@/pages/dashboard/Receipts/Receipt/Receipt';

const Test = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Test')), 500);
  });
});

const Companies = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Companies')), 500);
  });
});

const Currencies = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Currencies')), 500);
  });
});

const Admins = lazy(() => {
  return new Promise((reslove) => {
    // @ts-expect-error
    setTimeout(() => reslove(import('@/pages/dashboard/Admins')));
  });
});

const Banks = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Banks')), 500);
  });
});

const Plans = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Plans')), 500);
  });
});

const Accountants = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Accountants')), 500);
  });
});

const Receipts = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Receipts')), 500);
  });
});

const Support = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Support')), 500);
  });
});

const Declarations = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/dashboard/Declarations')), 500);
  });
});

const AccountantProfile = lazy(() => {
  const AccountantProfilePath = import(
    '@/pages/dashboard/Accountants/AccountantProfile'
  );

  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AccountantProfilePath), 500);
  });
});

const outletElements = [
  {
    href: '',
    element: <Navigate to="companies" />,
  },
  {
    href: 'test',
    element: <Test />,
  },
  {
    href: 'companies',
    element: <Companies />,
  },
  {
    href: 'currencies',
    element: <Currencies />,
  },
  {
    href: 'admin-management',
    element: <Admins />,
  },
  {
    href: 'banks',
    element: <Banks />,
  },
  {
    href: 'plans',
    element: <Plans />,
  },
  {
    href: 'accountants',
    element: <Accountants />,
  },
  {
    href: 'receipts',
    element: <Receipts />,
  },
  {
    href: 'receipts/:id/idx/:index/page/:page_number',
    element: <ReceiptPage />,
  },
  {
    href: 'accountants/:id',
    element: <AccountantProfile />,
  },
  {
    href: 'support',
    element: <Support />,
  },

  {
    href: 'declarations',
    element: <Declarations />,
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }) => (
          <Route key={href} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        {/* //TODO: change allowed roles to `Role.admin` */}
        <RolesGuard allowedRoles={[Role.ADMIN]}>
          <DashboardLayout />
        </RolesGuard>
      </AuthGuard>
    ),
    path: 'dashboard',
  },
];

export default routes;
