import type { FC } from 'react';

import { ArrowDropDown, ExitToAppOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {
  useAppComponentVisible,
  useAppDispatch,
  useAppSelector,
} from '@/hooks';
import { setConnectedUser } from '@/store/actions/action-creators';
import { cls } from '@/utils/funcs';

import styles from '../Dashboard.module.scss';

interface ProfileProps {}

const Profile: FC<ProfileProps> = () => {
  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);

  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const runAndHideMenu = (callback: any) => {
    callback();
    setIsMenuOpen(false);
  };

  const handleSignOut = () => {
    dispatch(setConnectedUser(null));
    localStorage.removeItem('access-token');
    navigate('/');
  };

  const menuItems = [
    {
      type: 'item',
      icon: <ExitToAppOutlined className="text-xs rotate-180" />,
      label: 'Sign Out',
      onClick: () => runAndHideMenu(() => handleSignOut()),
    },
  ];

  const handleToggleMenu = () =>
    setIsMenuOpen((isMenuOpen) => (isMenuOpen ? false : true));

  return (
    <div className={styles.profile}>
      <div
        className={cls(
          'flex items-center gap-2 ml-2 justify-center h-full',
          styles.menuToggler
        )}
        onClick={handleToggleMenu}
        onBlur={() => setIsMenuOpen(false)}
      >
        <img
          alt="fr"
          src="https://res.cloudinary.com/devdfbdfk/image/upload/v1674816800/img_e3p6l0.svg"
        />

        <p className="f-14px font-size-sm whitespace-nowrap font-semibold color-black select-none">
          {connectedUser.firstName} {connectedUser.lastName}
        </p>

        <ArrowDropDown />
      </div>

      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          {menuItems.map((item, idx) =>
            item.type === 'divider' ? (
              <hr key={idx} />
            ) : (
              <div key={idx} onClick={item.onClick}>
                {item.icon}
                <p>{item.label}</p>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
