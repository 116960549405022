import { lazy } from 'react';

import { Navigate, Route } from 'react-router-dom';

import { AuthLayout } from '@/components/layouts';
import { PublicGuard } from '@/lib/guards';

import type { RouteType } from '.';

const SignIn = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/auth/SignIn')), 500);
  });
});

const outletElements = [
  {
    element: <Navigate to="sign-in" />,
    href: '',
  },
  {
    element: <SignIn />,
    href: 'sign-in',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <PublicGuard>
        <AuthLayout />
      </PublicGuard>
    ),
    path: 'auth',
  },
];

export default routes;
