import type { ReactNode } from 'react';

import authRoutes from './auth';
import dashboardRoutes from './dashboard';
import otherRoutes from './other';

export type RouteType = {
  element: ReactNode;
  path: string;
  children?: ReactNode;
};

const routes: RouteType[] = [authRoutes, dashboardRoutes, otherRoutes].reduce(
  (routes, allRoutes) => [...allRoutes, ...routes],
  []
);

export default routes;
