import type { SignInFormType } from '@/lib/forms';

import { adminsAPI } from './apis';
import type { AdminSubmitType } from './models';

export default class AdminsService {
  static signIn(
    data: SignInFormType,
    recaptchaToken: string | null,
    signal?: AbortSignal
  ) {
    return adminsAPI.post(`/v1/auth/login`, data, {
      signal,
      headers: {
        'X-recaptcha-token': recaptchaToken,
      },
    });
  }

  static authMe(signal?: AbortSignal) {
    return adminsAPI.get(`/v1/auth/me`, {
      signal,
    });
  }

  static getAdmins(filters: any, signal?: AbortSignal) {
    return adminsAPI.get(`/v1/users`, {
      headers: { 'Content-type': 'multipart/form-data' },
      signal,
      params: filters,
    });
  }

  static createAdmin(data: AdminSubmitType, signal?: AbortSignal) {
    return adminsAPI.post(`/v1/users`, data, {
      signal,
    });
  }

  static deleteAdmin(adminId: string, signal?: AbortSignal) {
    return adminsAPI.delete(`/v1/users/${adminId}`, {
      signal,
    });
  }
}
