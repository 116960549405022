import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
// eslint-disable-next-line import/no-named-as-default
import ReactCountryFlag from 'react-country-flag';

import { useAppComponentVisible, useAppLanguage } from '@/hooks';
import { cls } from '@/utils/funcs';

import styles from '../Dashboard.module.scss';

const languages = [
  {
    name: 'English',
    countryCode: 'gb',
    languageCode: 'en',
  },
  {
    name: 'Français',
    countryCode: 'fr',
    languageCode: 'fr',
  },
];

interface LangSelectorProps {}

const LangSelector: FC<LangSelectorProps> = () => {
  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);
  const [langObj, setLangObj] = useState<any>(null);
  const { lang, changeLang } = useAppLanguage();

  useEffect(() => {
    setLangObj(languages.find((langObj) => langObj.languageCode === lang));
  }, [lang]);

  const handleToggleMenu = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);

  return (
    <div
      className={styles.langSelector}
      onClick={handleToggleMenu}
      onBlur={() => setIsMenuOpen(false)}
    >
      {langObj && (
        <div
          className={cls(
            'flex items-center gap-2 justify-center h-full',
            styles.menuToggler
          )}
        >
          <ReactCountryFlag
            countryCode={langObj.countryCode}
            svg
            className="text-[26px] rounded-lg"
          />
          <p className="f-14px font-size-sm whitespace-nowrap font-semibold color-black w-[90px]">
            {langObj.name} ({langObj.countryCode.toUpperCase()})
          </p>
          <ArrowDropDown />
        </div>
      )}

      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          {languages.map(({ name, countryCode, languageCode }, idx) => (
            <Fragment key={idx}>
              <div onClick={() => changeLang(languageCode)}>
                <ReactCountryFlag
                  countryCode={countryCode}
                  svg
                  className="text-[22px] rounded-lg"
                />
                <p>
                  {name} ({countryCode.toUpperCase()})
                </p>
              </div>

              <hr />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default LangSelector;
