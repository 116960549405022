import { lazy } from 'react';

import type { RouteType } from '.';

const NotFound = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/other/NotFound')), 500);
  });
});

const Welcome = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/other/Welcome')), 500);
  });
});

const routes: RouteType[] = [
  {
    element: <Welcome />,
    path: '/',
  },
  { element: <NotFound />, path: '*' },
];

export default routes;
