import type { FC } from 'react';
import { useEffect } from 'react';

import {
  getInitialTheme,
  useAppDispatch,
  useAppSelector,
  useBrowserFingerprint,
} from './hooks';
import { SuspenseFallback } from './pages/other';
import Router from './router';
import { AdminsService } from './services';
import {
  setConnectedUser,
  setIsPreloadState,
} from './store/actions/action-creators';

const App: FC = () => {
  const isPreloadState = useAppSelector((store) => store.global.isPreloadState);
  const dispatch = useAppDispatch();
  const isDoneFingerprinting = useBrowserFingerprint();

  useEffect(() => {
    if (!isDoneFingerprinting) return;
    getInitialTheme();

    const accessToken = localStorage.getItem('access-token');
    if (!accessToken) {
      dispatch(setIsPreloadState(false));
      return;
    }

    const controller = new AbortController();
    AdminsService.authMe()
      .then((res: any) => {
        dispatch(setConnectedUser(res.data));
        dispatch(setIsPreloadState(false));
      })
      .catch(() => dispatch(setIsPreloadState(false)));

    return () => controller.abort();
  }, [isDoneFingerprinting]);

  if (isPreloadState) return <SuspenseFallback />;

  return <Router />;
};

export default App;
