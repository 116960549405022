import type { FC } from 'react';
import React from 'react';

import { Close } from '@mui/icons-material';
import { Dialog } from '@mui/material';

import { Button } from '@/components/global';
import { useAppTranslation } from '@/hooks';

const MODAL_TEMPLATE = {
  danger: {
    bgColor: '#d9534f',
  },
  info: {
    bgColor: '#5d5ff8',
  },
};

interface ConfirmationModalProps {
  /**
   * @default false
   */
  isOpen: boolean;

  /**
   * @default '''
   */
  onClose: Function;

  /**
   * @default '''
   */
  action: Function;

  /**
   * @default '''
   */
  actionName: string;

  /**
   * @default '''
   */
  title: string;

  /**
   * @default '''
   */
  message: string;

  /**
   * @default danger
   */
  sensitivity: 'danger' | 'info';

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default false
   */
  disabled?: boolean;
}

/**
 *
 * @param {boolean} isOpen display and hide the modal
 * @param {Function} onClose responsible function to close the modal
 * @param {Function} action primary action
 * @param {string} title Title of the modal
 * @param {string} message message of the modal
 * @param {string} sensitivity modal sensitivity
 * @param {boolean} isLoading if the action require time to complete
 * @param {boolean} disabled disable the action
 * @returns
 */
const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen = false,
  sensitivity = 'danger',
  disabled = false,
  isLoading = false,
  onClose,
  action,
  title,
  message,
  actionName,
}) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        style: { borderRadius: 12 },
      }}
    >
      <div className='px-9 py-5 flex flex-col gap-4 max-w-[500px] rounded-xl border-2 border-[#DEE2E6]"'>
        <div
          onClick={() => {
            onClose();
          }}
          className="flex justify-end cursor-pointer"
        >
          <Close />
        </div>

        <div className="flex flex-col gap-4 mb-3">
          <h3 className="font-semibold text-xl text-center">{title}</h3>
          <p className="text-center text-[#989b9e]">{message}</p>
        </div>

        <div className="flex flex-col gap-3 mt-4">
          <Button
            onClick={() => {
              onClose();
            }}
            cls={`h-12 px-4 py-2 rounded-xl w-full bg-[#d8d8d8] text-white flex items-center justify-center disabled:opacity-60 hover:opacity-80 duration-200`}
            label={t('Close')}
          />
          <Button
            onClick={() => {
              action();
            }}
            cls={`h-12 px-4 py-2 rounded-xl w-full text-white flex items-center justify-center disabled:opacity-60 hover:opacity-80 duration-200`}
            label={actionName}
            isLoading={isLoading}
            disabled={disabled}
            style={{
              backgroundColor: MODAL_TEMPLATE[sensitivity].bgColor,
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
