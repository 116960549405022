import type { ComponentProps, FC, ReactNode } from 'react';

import type { IconPosition } from '..';
import { LoadingSpinner, getCls } from '..';

const DEFAULT_CLS =
  'h-12 px-4 py-2 rounded-xl bg-blue-600 text-white flex items-center justify-center';
const DEFAULT_LABEL_CLS = 'px-1';

interface ButtonProps extends ComponentProps<'button'> {
  /**
   * @default ''
   */
  cls?: string;

  /**
   * @default ''
   */
  label?: string | ReactNode /* | DefaultTFuncReturn */;

  /**
   * @default ''
   */
  labelCls?: string;

  /**
   * @default true
   */
  applyDefaultCls?: boolean;

  /**
   * @default true
   */
  applyDefaultLabelCls?: boolean;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default false
   */
  isLoading?: boolean;

  /**
   * @default null
   */
  loadingSpinnerProps?: ComponentProps<typeof LoadingSpinner>;

  // children?: string | ReactNode;
}

/**
 * Global Button Component
 * @param {string} label label text
 * @param {string} cls button class
 * @param {string} labelCls label class
 * @param {boolean} applyDefaultCls whether to apply the default button class
 * @param {boolean} applyDefaultLabelCls whether to apply the default label class
 * @param {ReactNode} icon icon component
 * @param {IconPosition} iconPosition icon position
 * @param {boolean} isLoading whether to show the loading spinner
 * @param {any[]} ...props list of props to pass to the native button element
 * @example <Button label="Submit" icon={<CheckCircleIcon />} cls="hover:bg-gray-200 transition duration-150 ease-out hover:ease-in" isLoading={isLoading} loadingSpinnerProps={{ strokeColor: 'lightgrey' }} />
 */
const Button: FC<ButtonProps> = ({
  cls = '',
  label = '',
  labelCls = '',
  applyDefaultCls = true,
  applyDefaultLabelCls = true,
  icon = null,
  iconPosition = 'left',
  isLoading = false,
  loadingSpinnerProps = null,
  // children = null,
  ...props
}) => {
  cls = getCls(applyDefaultCls, DEFAULT_CLS, cls);

  labelCls = getCls(applyDefaultLabelCls, DEFAULT_LABEL_CLS, labelCls);

  return (
    <button {...props} className={cls}>
      {isLoading ? (
        <LoadingSpinner {...loadingSpinnerProps} />
      ) : (
        <>
          {iconPosition === 'left' && icon}
          {typeof label === 'string' ? (
            <span className={labelCls}>
              {label}
              {/* {children} */}
            </span>
          ) : (
            label
          )}
          {iconPosition === 'right' && icon}
        </>
      )}
    </button>
  );
};

export default Button;
