import axios from 'axios';

import {
  defaultAPIRequestMiddleware,
  defaultAPIResponseMiddleware,
} from '@/lib/interceptors';

const BASE_URL = '/api/v1/';

const mockApi = axios.create({
  baseURL: BASE_URL,
});

mockApi.interceptors.request.use(defaultAPIRequestMiddleware, (error) =>
  Promise.reject(error)
);

mockApi.interceptors.response.use(
  (res) => res,
  (error) => defaultAPIResponseMiddleware(error, mockApi)
);

export default mockApi;
