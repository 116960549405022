import type { FC } from 'react';

import { Company, LangSelector, Notification, Profile, ThemeButton } from '.';

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  return (
    <div className="bg-first scroll-bar-style z-index z-50 pt-4 pb-4 pr-6 w-full gap-12 flex items-center fixed top-0 ">
      <div className="grow flex">
        <div className="min-w-[250px] flex">
          <div className="w-[80%] ml-auto mr-auto">
            {/* <img
              className="w-full h-full pl-3"
              alt="logo test"
              src="https://res.cloudinary.com/devdfbdfk/image/upload/v1674809174/Logo_3_mhhb8p.svg"
            /> */}

            <Company />
          </div>
        </div>
      </div>

      <div className="flex gap-8">
        <ThemeButton />
        <LangSelector />
        <Notification />
        <Profile />
      </div>
    </div>
  );
};

export default Navbar;
