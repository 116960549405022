import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks';

interface RolesGuardProps {
  children: JSX.Element | JSX.Element[];
  allowedRoles: Role[];
}

const RolesGuard: FC<RolesGuardProps> = ({ children, allowedRoles }) => {
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const hasPermission = allowedRoles.some((role) =>
    connectedUser.roles?.includes(role)
  );

  return hasPermission ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/sign-in" replace />
  );
};

export default RolesGuard;

export enum Role {
  USER = 'USER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}
