/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ArrowBack,
  KeyboardBackspaceRounded,
  NorthRounded,
  SouthRounded,
  ZoomOutMapRounded,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Modal, ModalGateway } from 'react-images';
import InfiniteScroll from 'react-infinite-scroller';
import { RotatingLines } from 'react-loader-spinner';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import type { DateValueType } from "react-tailwindcss-datepicker";
import Datepicker from "react-tailwindcss-datepicker"; 

import {
  Button,
  Input,
  LoadingSpinner,
  RSelect,
} from '@/components/global';
import {  useAppSelector, useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';


import { ObjectPdf } from '@/pages/dashboard/common';

import {
  SettingsService,
} from '@/services';
import { cls } from '@/utils/funcs';

import { InvoiceType, StatusEnum } from '../enums';
import { ImageReceipt } from './index';
import OperationService from '@/services/OperationService';
import { ConfirmationModal } from '../../common/ConfirmationModal';
import type { Receipt } from '@/services/models/ReceiptsModels';
import type { CurrencyType } from '@/services/models';
import { ReceiptFormUpdateSchema } from '@/lib/forms/receipts-forms';
import ReceiptIcon from '@mui/icons-material/Receipt';
import moment from 'moment';


interface ReceiptProps {}

const ReceiptPage: FC<ReceiptProps> = () => {
  const { id, index, page_number } = useParams();
  const navigate = useNavigate();
 
  const tableRef = useRef<HTMLTableElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [receipt, setReceipt] = useState<Receipt>(null as unknown as Receipt);
  const [receiptId, setReceiptId] = useState(id);
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      supplierName: receipt?.supplierName,
      clientName: receipt?.clientName,
      totalAmount: receipt?.totalAmount,
      totalTaxAmount:receipt?.totalTaxAmount,
      netAmount: receipt?.netAmount,
      invoiceDate: receipt?.invoiceDate,
      invoiceType: receipt?.invoiceType ?? InvoiceType.PURCHASE,
      currency: receipt?.currency,
      currencyTnd:
        currencies.filter((res) => res?.code === 'TND')[0]?.code ?? null,
    },
    mode: 'all',
    resolver: yupResolver(ReceiptFormUpdateSchema),
  });

  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedReceiptIndex, setSelectedReceiptIndex] = useState(
    parseInt(index ?? '0')
  );
  const [isRotatingLines, setIsRotatingLines] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useAppTranslation();
  const columns = [t('Receipt'), t('Status')];
  const [isLoading, setIsLoading] = useState(false);
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(watch('invoiceDate')
  ? {endDate:moment(watch('invoiceDate')).format('YYYY-MM-DD'),startDate:moment(watch('invoiceDate')).format('YYYY-MM-DD')}
  : '');
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
    useState<boolean>(false);
    const [formData, setFormData] = useState({});
  const pageSize = 20;
  const toggleModal = () =>
    setIsModalOpen((isModalOpen: boolean) => !isModalOpen);
  
  useEffect(() => {
    OperationService.getReceiptsWithPagination(
      null,
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((receipt) => {
      setReceipts(receipt.data?.receipts);
      setTotalPages(receipt.data.totalNumberOfPages);
      setIsRotatingLines(false);
    });
  }, [pageSize]);

  useEffect(() => {
    setIsRotatingLines(true);

    OperationService.getReceiptsWithPagination(
      null,
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((response) => {
      setReceipts(response.data?.receipts);
      setTotalPages(response.data.totalNumberOfPages);
      setIsRotatingLines(false);
    });
  }, []);

  const handleScroll = (pageNumber: number) => {
    setPageNumber(pageNumber + 1);

    OperationService.getReceiptsWithPagination(
      null,
      pageNumber,
      pageSize * Number(page_number),
      StatusEnum.ALL,
      null
    ).then((receipt) => {
      const newReceiptsList = receipts.concat(receipt.data?.receipts);

      setReceipts(newReceiptsList);
      setTotalPages(receipt.data.totalNumberOfPages);
    });
  };

  const fetchReceipt = (signal?: AbortSignal) => {
    OperationService.getReceipt(receiptId ?? '', signal).then((res) => {
      setReceipt(res.data);
      reset({ ...res.data });
      setValue(
        'currencyTnd',
        currencies.filter((res) => res?.code === 'TND')[0]?.code
      );
    });
  };


  const onSubmit = (data: any) => {
    setIsLoading(true);
    const updatedReceipt: Receipt = {
      ...receipt,
      supplierName: data?.supplierName,
      clientName: data?.clientName,
      invoiceDate: selectedDate?.startDate ?? null,
      invoiceType:  data.invoiceType,
      totalAmount: parseFloat(data?.totalAmount),
      totalTaxAmount: data?.totalTaxAmount,
      netAmount: data?.netAmount,
      currency: data.currency,
      id: receipt?.id,
    };
    const controller = new AbortController();
    OperationService.updateReceipt(
      receiptId,
      updatedReceipt,
      controller?.signal
    )
      .then((res) => {
       
        setIsLoading(false);
        appToast.success(
            t('Receipt updated successfully')
        );
        setReceipt(res?.data);
        setConfirmationModalIsOpen(false);
        const updatedReceipts = [...receipts];
        const updatedReceiptIndex = updatedReceipts.findIndex(
          (r) => r.id === res?.data?.id
        );

        if (updatedReceiptIndex !== -1) {
          updatedReceipts[updatedReceiptIndex] = res?.data;
          setReceipts(updatedReceipts);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        appToast.error(t(error.response.data.code));
      });
  };



  useEffect(() => {
    const controller = new AbortController();

    fetchReceipt(controller.signal);
    setSelectedDate(watch('invoiceDate')
    ? {endDate:moment(watch('invoiceDate')).format('YYYY-MM-DD'),startDate:moment(watch('invoiceDate')).format('YYYY-MM-DD')}
    : '');
    return () => controller.abort();
  }, [receiptId, currencies]);


  
  


  const getFileExtension = (filename: string | undefined): string => {
    const extension = filename?.split('.').pop()?.toLowerCase();
    return extension ?? '';
  };

  // Add an event listener for keydown on the window
  const handleKeys = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      tableRef?.current?.focus();
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    Promise.all([
      SettingsService.getCurrencies(controller.signal),
    ]).then(
      ([
        currenciesResponse,
   
      ]) => {
        setCurrencies(currenciesResponse.data);
      }
    );

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.focus();
    }

    setSelectedReceiptIndex(parseInt(index || '0'));

    window.addEventListener('keydown', handleKeys);

    return () => window.removeEventListener('keydown', handleKeys);
  }, []);


  function getStatusClass(fileStatus: any) {
    if (fileStatus === StatusEnum.VALIDATED) {
      return 'text-[#14A44D]';
    } else if (
      fileStatus === StatusEnum.ANALYSIS_SUCCESS ||
      fileStatus === StatusEnum.ANALYSIS_IN_PROGRESS
    ) {
      return 'text-[#E4A11B]';
    } else {
      return 'text-[#DC4C64]';
    }
  }

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        handleArrowUp();
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleArrowDown();
        break;

      default:
        e.preventDefault();
        break;
    }
  };

  const handleArrowUp = () => {
    if (selectedReceiptIndex > 0) {
      setSelectedReceiptIndex((prevIndex) => prevIndex - 1);
      setReceiptIdFromIndex(selectedReceiptIndex - 1);
    } else {
      setReceiptIdFromIndex(receipts.length - 1);
      setSelectedReceiptIndex(() => receipts.length - 1);
    }
  };

  const handleArrowDown = () => {
    if (selectedReceiptIndex < receipts.length - 1) {
      setSelectedReceiptIndex((prevIndex) => prevIndex + 1);
      setReceiptIdFromIndex(selectedReceiptIndex + 1);
    } else {
      setReceiptIdFromIndex(0);
      setSelectedReceiptIndex(() => 0);
    }
  };

  const setReceiptIdFromIndex = (index: number) => {
    if (receipts[index]) {
      setReceiptId(receipts[index].id);
    }
  };

  

  function getActionSubmit() {
    const isAccountant = !!connectedUser.roles?.includes('ACCOUNTANT');
    const isValidated = receipt?.fileStatus === StatusEnum.VALIDATED;

    if (isAccountant && !isValidated) {
      return t('Validate');
    } else if (isValidated && isAccountant) {
      return t('Invalidate');
    } else {
      return t('Save changes');
    }
  }

  const handleOpenModal = (data: any) => {
    setFormData(data); // Store form data in state
    setConfirmationModalIsOpen(true); // Open the confirmation modal
  };

  const handleDateChange = (newDate : DateValueType) => {
    setSelectedDate(newDate);
  };

  return (
    // <section className="mt-[81px] p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
    <section className="mt-[81px] pl-16 p-8 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full h-[calc(100vh-81px)] overflow-y-scroll">
      {confirmationModalIsOpen && (
        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          onClose={() => {
            setConfirmationModalIsOpen(false);
          }}
          action={() => {
           onSubmit(formData)
          }}
          isLoading={isLoading}
          title={t('RECEIPT_VERIFICATION_UPDATE')}
          message={t(
            `SAVE_ADMIN_DESCRIPTION`
          )}
          sensitivity="danger"
          actionName={t('Save changes')}
        />
      )}

      {/* HEADER */}
      <div className="flex space-x-4 mb-2 items-center">
        <Button
          icon={<KeyboardBackspaceRounded className="!h-6 !w-6 ml-1 " />}
          label={t('Back to receipts')}
          cls="border-[2px] bg-transparent text-[#0075E7] border-[#0075E7] hover:opacity-80 transition-opacity duration-200"
          onClick={() => navigate(`/dashboard/receipts`)}
        />
      </div>

      <div className="flex gap-4 max-h-[calc(79vh)] lg:flex-row flex-col">
        <div className="lg:flex flex-col min-w-[350px] rounded-3xl bg-white  py-8 max-w-sm  overflow-hidden hidden">
          <div className="px-6 flex items-center justify-between mb-4">
            <p className="text-[#171725] font-semibold text-xl  whitespace-nowrap overflow-ellipsis">
              {t('My receipt')}
            </p>

            <Tooltip title="You can navigate using the arrow buttons">
              <div className="flex gap-1">
                <div className="border-2 border-solid border-[#7B7B8D] p-1 rounded-lg flex justify-center items-center h-fit">
                  <NorthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#7B7B8D"
                  />
                </div>
                <div className="border-2 border-solid border-[#005AB3] p-1 rounded-lg flex justify-center items-center h-fit">
                  <SouthRounded
                    style={{ height: 15, width: 15 }}
                    htmlColor="#005AB3"
                  />
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="overflow-y-scroll max-h-full ml-2">
            {!isRotatingLines ? (
              <InfiniteScroll
                pageStart={1}
                loadMore={handleScroll}
                hasMore={totalPages > pageNumber}
                useWindow={false}
                loader={
                  <div
                    className="w-full flex items-center justify-center py-4"
                    key={0}
                  >
                    <LoadingSpinner strokeColor="#6a94ff" />
                  </div>
                }
              >
                <table
                  className="w-full text-left text-gray-500 focus:outline-none"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={selectedReceiptIndex}
                  onKeyDown={handleKeyDown}
                  ref={tableRef}
                >
                  <thead className="text-gray-700 border-b">
                    <tr>
                      {columns.map((column, idx) => (
                        <th
                          scope="col"
                          className="px-3 py-4 text-xs font-bold text-[#8D98AF] bg-[#F8FAFB]"
                          key={idx}
                        >
                          {column}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {receipts.map(
                    ({ fileName, id, fileStatus }, idx) => (
                      <tr
                        style={{
                          backgroundColor:
                            receiptId === id ? '#F2F2F2' : 'white',
                        }}
                        className={`${
                          receiptId === id ? 'bg-[#F2F2F2]' : 'bg-white'
                        } bg-white border-b text-xs cursor-pointer hover:bg-[#F2F2F2] transition-colors dark:hover:bg-[#1b1d2c] duration-200`}
                        key={id}
                        onClick={() => {
                          setReceiptId(id);
                          navigate(
                            `/dashboard/receipts/${id}/idx/${idx}/page/${pageNumber}`
                          );
                        }}
                      >
                        <td className="px-3 py-4">
                          { fileName ?? '--'}
                        </td>
                        <td
                          className={cls(
                            'px-3 py-4',
                            getStatusClass(fileStatus)
                          )}
                        >
                           <ReceiptIcon htmlColor="#0075E7" style={{marginTop:5,marginBottom:5}}/>

                        </td>
                      </tr>
                    )
                  )}
                </table>
              </InfiniteScroll>
            ) : (
              <div className="flex flex-row justify-center mt-52">
                <RotatingLines visible={true} strokeColor="grey" width="30" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-[1.4] rounded-3xl bg-white px-2 py-4 h-[calc(80vh)] scroll-mb-16 min-w-[350px]">
          <button
            className="text-[#7B7B8D] font-semibold text-lg flex gap-2 justify-center items-center mb-5 cursor-pointer hover:opacity-80 transition-opacity duration-300"
            onClick={toggleModal}
            onKeyDown={toggleModal}
          >
            <ZoomOutMapRounded />
            <p>{t('View in fullscreen')}</p>
          </button>

          {/* @ts-expect-error */}
          <ModalGateway>
            {isModalOpen ? (
              <Modal onClose={toggleModal} allowFullscreen={false}>
                <Carousel>
                  {/*  */}
                  <></>
                  <div>
                    <div className="mt-12 p-4">
                      <button
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        type="button"
                        className="flex gap-2 items-center p-3 cursor-pointer text-white ease-in-out duration-300 hover:text-[#bbbbbb] hover:border-[#bbbbbb] border border-white rounded-lg"
                      >
                        <ArrowBack />
                        <span>{t('Go back to receipt')}</span>
                      </button>
                    </div>

                    {receipt?.fileUrl &&
                    getFileExtension(receipt?.fileName) === 'pdf' ? (
                      <ObjectPdf
                        clsContainer="flex flex-col"
                        url={
                          (receipt?.highlightedFileId
                            ? receipt?.highlightedFileUrl
                            : receipt?.fileUrl) ?? ''
                        }
                        cls={'w-[150vh] h-[90vh]'}
                      />
                    ) : (
                      <ImageReceipt
                        fullscreen={true}
                        url={
                          (receipt?.highlightedFileId
                            ? receipt?.highlightedFileUrl
                            : receipt?.fileUrl) ?? ''
                        }
                        alt="receipt"
                        cls="w-[65vh] h-[80vh]"
                        clsContainer=" cursor-move"
                      />
                    )}
                  </div>
                </Carousel>
              </Modal>
            ) : null}
          </ModalGateway>

          <div className="rounded-xl bg-[#F4F4F4] w-full overflow-hidden h-fit">
            {receipt?.fileUrl &&
            getFileExtension(receipt?.fileName) === 'pdf' ? (
              <ObjectPdf
                clsContainer="flex flex-col w-full"
                url={
                  (receipt?.highlightedFileId
                    ? receipt?.highlightedFileUrl
                    : receipt?.fileUrl) ?? ''
                }
                cls={'w-full h-[63vh]'}
              />
            ) : (
              <ImageReceipt
                url={
                  (receipt?.highlightedFileId
                    ? receipt?.highlightedFileUrl
                    : receipt?.fileUrl) ?? ''
                }
                alt="receipt"
                cls={'max-h-[calc(60vh)]'}
              />
            )}
          </div>
        </div>
        <div>
          <div className="flex rounded-3xl bg-white px-4 py-6 flex flex-col relative gap-4 relative lg:w-[450px] max-h-[calc(79vh)]">
          <div className="overflow-y-scroll p-4">
            <form onSubmit={handleSubmit(handleOpenModal)} id="receipt-form">
              <div className="flex flex-col gap-4 relative w-full">
              <RSelect
                  control={control}
                  id="invoiceType"
                  refs={register('invoiceType')}
                  label={t('Invoice Type')}
                  options={[
                    {
                      label: t('Purchase invoice'),
                      value: InvoiceType.PURCHASE,
                    },
                    { label: t('Sales invoice'), value: InvoiceType.SALE },
                  ]}
                  labelCls="text-[#7B7B8D] text-md"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '48px',
                      borderRadius: '10px',
                      border: '2px solid rgb(210, 209, 214) !important',
                    }),
                    option: (provided, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused
                        ? '#e5e7eb'
                        : 'transparent',
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                      '&:hover': {
                        backgroundColor: '#e5e7eb',
                      },
                    }),
                    singleValue: (provided, state: any) => ({
                      ...provided,
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                      boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                      backgroundColor: '#fff',
                    }),
                  }}
                />
                <div className='flex flex-col gap-2 w-full'>
                  <label htmlFor='datepicker' className='block text-[#7B7B8D] text-md'>
                   {t("Date")}
                  </label>
                  <Datepicker 
                  inputId="datepicker"
                  inputClassName="h-12 px-4 py-2 rounded-xl border-2 border-solid border-[#D2D1D6] w-full focus:border-none focus:outline-none"
                  value={selectedDate} 
                  displayFormat={"DD/MM/YYYY"} 
                  asSingle
                  onChange={handleDateChange}
                  useRange={false} 
                  dateLooking='middle'
/> 
</div>

<Input
                    refs={register('supplierName')}
                    label={`${t('supplierName')}`}
                    type="text"
                    step={'any'}
                    labelCls="text-[#7B7B8D] text-md"
                    cls="disabled:opacity-60"
                    errorMsg={
                      errors.supplierName?.message &&
                      t('supplier is a required field')
                    }
                  />

<Input
                    refs={register('clientName')}
                    label={`${t('clientName')}`}
                    type="text"
                    step={'any'}
                    labelCls="text-[#7B7B8D] text-md"
                    cls="disabled:opacity-60"
                    errorMsg={
                      errors.clientName?.message &&
                      t('supplier is a required field')
                    }
                  />

             
                  {/* Your Input component */}
                  <Input
                    refs={register('totalAmount')}
                    label={`${t('totalAmount')}${
                      receipt?.currency && receipt?.currency !== 'TND'
                        ? ` (${receipt?.originalAmount} ${receipt?.currency})`
                        : ''
                    }`}
                    type="number"
                    step={'any'}
                    labelCls="text-[#7B7B8D] text-md"
                    cls="disabled:opacity-60"
                    errorMsg={
                      errors.totalAmount?.message &&
                      t('totalAmount is a required field')
                    }
                  />
                             <Input
                    refs={register('totalTaxAmount')}
                    label={`${t('totalTaxAmount')}`}
                    type="number"
                    step={'any'}
                    labelCls="text-[#7B7B8D] text-md"
                    cls="disabled:opacity-60"
                    errorMsg={
                      errors.totalAmount?.message &&
                      t('totalAmount is a required field')
                    }
                  />
                             <Input
                    refs={register('netAmount')}
                    label={`${t('netAmount')}
                    
                    `}
                    type="number"
                    step={'any'}
                    labelCls="text-[#7B7B8D] text-md"
                    cls="disabled:opacity-60"
                    errorMsg={
                      errors.totalAmount?.message &&
                      t('totalAmount is a required field')
                    }
                  />

<RSelect
                  control={control}
                  id="currency"
                  refs={register('currency')}
                  label={t('currency')}
                  options={currencies.map(res => ({
                    label: res.name,
                    value: res.code,
                  }))}
                  labelCls="text-[#7B7B8D] text-md"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: '48px',
                      borderRadius: '10px',
                      border: '2px solid rgb(210, 209, 214) !important',
                    }),
                    option: (provided, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused
                        ? '#e5e7eb'
                        : 'transparent',
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                      '&:hover': {
                        backgroundColor: '#e5e7eb',
                      },
                    }),
                    singleValue: (provided, state: any) => ({
                      ...provided,
                      color: state.data.value === null ? '#7B7B8D' : '#111827',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '8px',
                      boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
                      backgroundColor: '#fff',
                    }),
                  }}
                />
              </div>
            </form>

            </div>
              <Button
                form="receipt-form"
                iconPosition="right"
                type="submit"
                label={getActionSubmit()}
                cls={`w-full hover:opacity-80 transition-opacity duration-200 disabled:opacity-60`}
              />
        </div>
        </div>
      </div>
    </section>
  );
};

export default ReceiptPage;
