const EN_TRANSLATIONS = {
  // ==============================
  // App - Auth - SignIn
  // ==============================
  "Welcome back to login. As an admin, you have access to manage our user's information.":
    "Welcome back to login. As an admin, you have access to manage our user's information.",
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Company Information',
  'Bank Accounts': 'Bank Accounts',
  Subscription: 'Subscription',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Find your company': 'Find your company',
  Light: 'Light',
  Dark: 'Dark',
  Performances: 'Performances',
  Receipts: 'Receipts',
  Transactions: 'Transactions',
  Statements: 'Statements',
  Documents: 'Documents',
  Notifications: 'Notifications',
  Settings: 'Settings',
  'Drag & drop': 'Drag & drop',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Upload a file',
  "Can't upload statement": "Can't upload statement",
  'Statement does not exist': 'Statement does not exist',
  Search: 'Search',
  Confirm: 'Confirm',
  update: 'update',
  'are you sure you want to delete this bank?':
    'Are you sure you want to delete {{name}}?',
  Email: 'Email',
  // ==============================

  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Hello, world!',
  // ==============================

  // ==============================
  // App - Dashboard - Companies
  // ==============================
  'Company List': 'Manage Companies',
  'Sorted by': 'Sorted by',
  'No more companies': 'No more companies',
  'No companies found': 'No companies found',
  'Error loading companies': 'Error loading companies',
  Name: 'Name',
  name: 'name',
  'Tax Id': 'Tax Id',
  Address: 'Address',
  address: 'address',
  'Legal Status': 'Legal Status',
  Date: 'Date',
  date: 'date',
  logo: 'logo',

  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Settings',
  Profile: 'Profile',
  Enterprise: 'Enterprise',
  // ==============================

    // ==============================
  // App - Declarations
  // ==============================
  'Declarations templates': 'Declarations templates',
  'Add declaration template': 'Add declaration template',
  'Update declaration template': 'Update declaration template',
  'title': 'Title',
  'due date': 'Due Date',
  'is payable': 'Is payable',
  'No templates found!':'No templates found!',
  Declarations: 'Declarations',
  'year': 'Year',
  
  
  // ==============================

  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Server error. Please try again later.',
  'Service Unavailable. Please try again later.':
    'Service Unavailable. Please try again later.',
  Unauthorized: 'Unauthorized',
  "You don't have permissions to access this resource.":
    "You don't have permissions to access this resource",
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occurred': 'Unexpected Error Occurred',
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page Not Found',
  // ==============================

  // App - Other - Currencies
  // ==============================
  'Authorized currencies': 'Authorized currencies',
  'Created at': 'Created at',
  Actions: 'Actions',
  'Add a new currency': 'Add a new currency',
  'Origin country': 'Origin country',
  'Currencies deleted successfully': 'Currencies deleted successfully',
  'Add currency': 'Add currency',
  'Update currency': 'Update currency',
  'Currency full name': 'Currency full name',
  Symbol: 'Symbol',
  'Currencies created successfully': 'Currencies created successfully',
  code: 'Code',
  decimal: 'Decimal',
  'Code already exists': 'Code already exists',
  'There are no currencies': 'There are no currencies',
  'you can add a new currency by clicking the button above':
    'you can add a new currency by clicking the button above',
  'Delete currency': 'Delete currency',
  "You're about to delete this currency, are you sure?":
    "You're about to delete this currency, are you sure?",
  // ==============================

  // App - Other - SideBar
  // ==============================
  'Business management': 'Business management',
  'Ref categories': 'Ref categories',
  'Ref VAT Rates': 'Ref VAT Rates',
  'Admin management': 'Admin management',
  'List of authorized currencies': 'List of currencies',
  'Bank list': 'Bank list',
  'Bank name': 'Bank name',
  // ==============================

  // App - Other - Bank
  // ==============================
  'There are no bank': 'There are no bank',
  'you can add a new bank by clicking the button above':
    'you can add a new bank by clicking the button above',
  'Bank deleted successfully': 'bank deleted successfully',
  'Update bank': 'Update bank',
  'Add bank': 'Add bank',
  'Delete Bank': 'Delete Bank',
  'Bank Logo': 'Bank Logo',
  'Add a new bank': 'Add a new bank',
  // ==============================

  // App - Other - Plans
  // ==============================
  Plans: 'Plans',
  'Add a new plan': 'Add a new plan',
  Status: 'Status',
  Price: 'Price',
  Order: 'Order',
  Displayable: 'Displayable',
  'There are no plans': 'There are no plans',
  'you can add a new plan by clicking the button above':
    'you can add a new plan by clicking the button above',
  'Display this plan': 'Display this plan',
  'Update plan': 'Update plan',
  'Delete plan': 'Delete plan',
  'Add new feature': 'Add new feature',
  'Please add a status.': 'Please add a status.',
  'Please add a price.': 'Please add a price.',
  'Please add an order.': 'Please add an order.',
  Feature: 'Feature',
  'Please add a feature.': 'Please add a feature.',
  "You're about to delete this plan, are you sure?":
    "You're about to delete this plan, are you sure?",
  'Add plan': 'Add plan',
  // ==============================

  // App - Other - Errors handlers
  // ==============================
  BANKS_BANK_ALREADY_EXISTS: 'Bank does already exist',
  BANKS_UNSUPPORTED_FILE_TYPE: 'The uploaded file type is not supported',
  'Please add a name': 'Please add a name',
  'Please add a code': 'Please add a code',
  'Your logo is not valid': 'Your logo is not valid',
  BANKS_BANK_DOES_NOT_EXIST: 'Bank does not exist',
  AUTH_BAD_CREDENTIALS: 'Email or password is incorrect.',
  AUTH_INVALID_RECAPTCHA_TOKEN: 'Invalid Recaptcha',
  'Something went wrong, please try again later':
    'Something went wrong, please try again later',
  ADMIN_USER_EXISTS: 'User already exists',
  ADMIN_ROLE_NONEXISTENT: "The role doesn't exist",
  ADMIN_SELF_DELETE_ERROR: "Can't delete self",
  'The password must be at least 8 characters long,':
    'The password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character.',
  'Oops! Page not found': 'Oops!\n Page not found',
  "This page doesn't exist or get removed. We suggest you back to home":
    "This page doesn't exist or get removed. We suggest you go back home.",
  'Oh no!': 'Oh no!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    'So sorry, something not working well please try again later or feel free to contact support.',
  'Something went wrong': 'Something went wrong',
  'Go back home': 'Go back home',
  // ==============================

  // App - Other - Admins
  // ==============================
  'Administrator management': 'Administrator management',
  'Add an admin': 'Add an admin',
  'There is no admin': 'There is no admin',
  'you can add a new admin by clicking the button above':
    'you can add a new admin by clicking the button above',
    "Supplier":"Supplier",
    "totalAmount": 'Amount incl',
    "totalTaxAmount": 'Amount incl',
    "netAmount":"Amount excl",
    "currency":"currency",
    "supplierName":"Supplier",
    "clientName":"Client",
    "invoiceDate":"Date",
    "INVOICE_DATE_REQUIRED":"Invoice date is a required field",
    'Invoice Type': 'Invoice Type',
    'Save changes': 'Save changes',
    "RECEIPT_VERIFICATION_UPDATE":"Are you sure to save this receipt ?",
    "SAVE_ADMIN_DESCRIPTION":"You are about to save this receipt with the information below , please confirm your action.",
    'No data table': "No data table",
    'No data found in your search on this page': "No data found in your search on this page",
    'Go to home page': "Go to home page",
    'My receipt': 'My receipt',
  // ==============================
};

export default EN_TRANSLATIONS;
